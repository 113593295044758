<template>
  <b-modal
      :id="modal.id"
      hide-header
      hide-footer
      size="lg"
      centered
      @hidden="onHidden"
      @show="onShow"
  >
    <div class="header">
      <div class="header-content">
        <p>Adicionar Arquivos</p>
        <Close class="close-icon" @click="closeModal" />
      </div>
    </div>
    <div class="body">
      <div class="body-content">
        <div v-if="forms.length" class="show-file">
          <FileContainer
              :forms="forms"
              :removeFile="removeFile"
              :changeName="changeName"
              @update-forms="forms = $event"
          />
          <div class="send-button d-flex justify-content-center">
            <b-button
                v-if="!sent"
                variant="primary"
                :loading="loading"
                :disabled="loading || sent"
                @click="uploadFiles"
            >
              <b-spinner v-if="loading" small variant="light" />
              {{ `Salvar arquivo${forms.length > 1 ? 's' : ''}` }}
            </b-button>
            <b-button
                v-else
                variant="outline-secondary"
                :disabled="loading"
                @click="closeModal"
            >
              Fechar
            </b-button>
          </div>
        </div>

        <div v-else class="set-file" @drop="addFiles" @dragover.prevent @drop.prevent>
          <div class="set-file-content">
            <p class="set-file-content-text">Arraste e solte os arquivos aqui</p>
            <div class="middle-set-file-content">
              <div class="middle-set-file-content-detail"></div>
              <p class="middle-set-file-content-text">ou</p>
              <div class="middle-set-file-content-detail"></div>
            </div>
            <label
                for="set-file-content-input"
                class="set-file-content-input"
            >
              Anexar arquivos
            </label>
            <input
                multiple
                id="set-file-content-input"
                name="set-file-content-input"
                type="file"
                accept=".jpeg, .jpg, .png, .pdf, .jps, .tiff"
                @change="addFiles"
            >
          </div>
        </div>

      </div>
    </div>
  </b-modal>
</template>
<script>
import {getCurrentUser} from "@/utils/localStorageManager"

export default {
  props: {
    fileType: String,
    budget: Object,
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    FileContainer: () => import('./Attachments/FileContainer')
  },
  data() {
    return {
      loading: false,
      sent: false,
      forms: [],
      isValidated: true,
      modal: {
        id: 'attachments'
      }
    }
  },
  methods: {
    removeFile(index) {
      const forms = [...this.forms]
      forms.splice(index, 1)
      this.forms = forms
    },
    changeName(index, value) {
      this.$set(this.forms[index], 'custom_name', value)
      this.$set(this.forms[index], 'canEdit', false)
    },
    suitName(name) {
      return name
          .replace(/\.[0-9a-z]+$/i, '')
          .replace(/[^\w\sA-Za-zÀ-ÖØ-öø-ÿ-()]/g, '')
    },
    async onShow() {
    },
    closeModal() {
      this.$bvModal.hide(this.modal.id)
    },
    onHidden() {
      this.forms = []
      this.sent = false
    },
    addFiles(e) {
      const forms = []
      const files = e.target.files || e.dataTransfer.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const type = file.type.split('/')[1]
        let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
        nameExt = nameExt.length > 1 ? nameExt[1] : '';
        if (![
          'pdf','jpg','jpeg','png', 'jps',
          'application/pdf','image/jpg',
          'image/jpeg','image/png', 'tiff'
        ].includes(type || nameExt)) {
          return this.$toast.error('Formato de arquivo não suportado!');
        }
        forms.push({
          file,
          type,
          type_id: 'intern',
          custom_name: file.name,
          error: false,
          progress: 0,
        })
      }
      this.forms = forms
    },
    async uploadFiles() {
      if (!this.isValid()) return
      this.loading = true;
      for (let index = 0; index < this.forms.length; index++) {
        const form = this.forms[index];
        const customName = this.suitName(form.custom_name)
        const dataForm = new FormData();

        dataForm.append(`file`, form.file);
        dataForm.append(`custom_name`, customName);
        dataForm.append(`budget_id`, this.budget.id);
        dataForm.append(`clinic_id`, this.budget.clinic_id.id);
        dataForm.append(`person_id`, getCurrentUser().id);

        try {
          const { data } = await this.api.sendBudgetFile(dataForm, {
            onUploadProgress: event => {
              form.progress = Math.round((event.loaded * 100) / event.total)
            }
          })
          this.budget.files.unshift(data.file)
        } catch(err) {
          form.error = err.message
          this.$toast.warning(`Não foi possível enviar o arquivo ${customName}`)
        } finally{
          this.loading = false;
        }
      }
      this.sent = true
      this.$toast.success('Arquivos enviados com Sucesso')
      this.$emit('uploadedFiles')
    },
    isValid() {
      let valid = true
      this.forms.forEach(form => {
        if(form)
          valid = true
      })

      return valid
    }
  }
}
</script>
<style lang="scss">
#attachments {
  border-radius: 8px;
  .modal-content {
    .modal-body {
      padding: 0;
      .header {
        border-bottom: 1px solid var(--neutral-200);
        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 16px 24px;
          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }
          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        .body-content {
          display: flex;
          flex-direction: column;

          .container {
            display: flex;
            flex-direction: column;
            padding: 24px;

            .title-body-content {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
            }

            .input-name-file {
              background: #FFFFFF;
              border: 1px solid #C6CEEB;
              box-sizing: border-box;
              border-radius: 8px;
              height: 56px;
              outline: none;
              text-indent: 16px;
              color: var(--type-active);
              margin: 8px 0 24px 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
            }

            .file-content {
              display: flex;
              flex-direction: column;

              .title-file-content {
                color: var(--dark-blue);
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
              }

              .file-type {
                display: flex;
                align-items: center;

                .input-file-type {
                  height: 24px;
                  width: 24px;
                  margin-right: 12px;
                  box-sizing: border-box;
                  border-radius: 100px;
                  cursor: pointer;
                }

                p + input {
                  margin-left: 24px;
                }

              }
            }
          }

          .show-file {
            border-top: 1px solid #C6CEEB;
            border-bottom: 1px solid #C6CEEB;
          }

          .set-file {
            margin: 24px;
            .set-file-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 300px;
              border: 1px dashed #305BF2;
              border-radius: 8px;

              .set-file-content-text {
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
              }

              .middle-set-file-content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 12px 0;

                .middle-set-file-content-detail {
                  width: 54px;
                  height: 2px;
                  background: var(--neutral-300);
                  border-radius: 16px;
                }

                .middle-set-file-content-text {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 130%;
                  padding: 0 14px;
                }
              }

              .set-file-content-input {
                width: auto;
                height: auto;
                padding: 8px 16px;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                color: var(--neutral-000);
                border-radius: 8px;
                background: var(--blue-700);
                cursor: pointer;
              }

              #set-file-content-input {
                display: none;
              }
            }
          }
        }
      }

      .footer {
        .footer-content {
          display: flex;
          justify-content: right;
          margin: 24px;

          button {
            width: auto;
            height: auto;
            padding: 14px 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            border-radius: 8px;
            background-color: var(--blue-500);
            color: var(--neutral-000);
          }
        }
      }
    }
  }

  .file-name-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      color: var(--type-placeholder);
    }
  }
  .edit-label {
    font-size: 12px;
    font-weight: 600;
    color: var(--type-placeholder);
  }
  .progress {
    margin: 5px 24px;
  }
  .send-button {
    display: flex;
    justify-content: right;
    padding: 40px 0;
  }
  .right-options {
    display: flex;
    align-items: center;
    gap: 10px;

    .laudable-check {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-size: 14px;
        font-weight: 600;
        color: var(--type-active);
      }
    }

  }
  .file-type {
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;

    .input-file-type {
      height: 24px;
      width: 24px;
      margin-right: 12px;
      box-sizing: border-box;
      border-radius: 100px;
      cursor: pointer;
    }

    p + input {
      margin-left: 24px;
    }
  }
}
</style>